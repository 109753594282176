import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Mail } from "react-feather"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import WorldMap from "../components/worldmap"
import Unicorns from "../components/unicorns"

export default class UnicornsPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Unicorn Companies Founded by Immigrants valued over $1B"
          description="Unicorns are startups and companies over one billion dollars. These unicorns were founded by immigrants to the United States — including Robinhood, Zoom, Discord, Peloton, Stripe, Gusto, and Uber."
        />

        <div id="landing-top">
          <div id="contains-map">
            <div id="map-container">
              <WorldMap />
            </div>
          </div>
          <div id="left-col">
            <div id="left-col-content">
              {/* <div className="page-icon-container">
          <Mic className="page-icon" />
        </div> */}
              <h1 id="interviews-header">
                Billion dollar companies founded by immigrants
              </h1>
              <h2 className="community-description">
                Unicorns are startups and companies worth over one billion
                dollars. These unicorns were founded by immigrants to the United
                States — including Robinhood, Zoom, Discord, Peloton, Stripe,
                Gusto, and Uber.
              </h2>
              <p className="description">
                Thanks to{" "}
                <OutboundLink
                  href="https://shikharsachdev.com"
                  className="inline-link"
                  target="_blank"
                >
                  Shikhar Sachdev
                </OutboundLink>{" "}
                of{" "}
                <OutboundLink
                  href="https://immigrantvalley.com"
                  className="inline-link"
                  target="_blank"
                >
                  Immigrant Valley
                </OutboundLink>{" "}
                for sharing this list.
              </p>
            </div>
          </div>
          <div id="right-col">
            <OutboundLink
              href="mailto:hi@immigrantfounders.com"
              target="_blank"
            >
              <div className="long-container colled">
                <div className="item-header">
                  <div className="long-icon-container">
                    <Mail className="slack-icon" />
                  </div>
                  Add a Unicorn
                  <div className="item-arrow">→</div>
                </div>
                <div className="item-description"></div>
              </div>
            </OutboundLink>
          </div>
        </div>
        <Unicorns />
      </Layout>
    )
  }
}
