import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-analytics'


const Unicorn = (props) => {


    return (
        <OutboundLink href={props.url} target="_blank">
            <div className="company-container">
                <div className="company-inner">
                    <div className="company-header">
                        {props.name}
                    </div>
                    <div className="company-description">
                        <p>{props.description}</p>
                    </div>
                    <div className="company-details">
                        <div className="founder-background">

                            Founded by
                    <div className="company-founder">
                                <p>{props.founder}</p>
                            </div>

                    From

                    <div className="company-nationality">
                                <p>{props.nationality}</p>
                            </div>

                    Valued at
                <div className="company-valuation">
                                <p>{props.valuation}</p>
                            </div>

                        </div>

                        <div className="company-background">
                            Incorporated in
                <div className="company-year">
                                <p>{props.year}</p>
                            </div>

                Headquarters
                <div className="company-location">
                                <p>{props.location}</p>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </OutboundLink>
    )
}

export default Unicorn