import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Unicorn from "../components/unicorn"


const Unicorns = ({ children }) => {
    return (

        <div className="companies">
            <div className="quote-column">

                <Unicorn
                    name="Actifio"
                    location="Waltham, MA"
                    year="2009"
                    nationality="🇮🇳 India"
                    founder="Ash Ashutosh"
                    description="Data management for  global enterprise customers"
                    employees="400"
                    valuation="$1.1B"
                    url="https://actifio.com" />

                <Unicorn
                    name="Affirm"
                    location="San Francisco, CA"
                    year="2011"
                    nationality="🇺🇦 Ukraine"
                    founder="Max Levchin "
                    description="Provides credit to consumers"
                    employees="400"
                    valuation="$1.8B"
                    url="https://affirm.com" />

                <Unicorn
                    name="Anaplan"
                    location="San Francisco, CA"
                    year="2006"
                    nationality="🇬🇧 United Kingdom "
                    founder="Michael Gould, Guy Haddleton"
                    description="Provides software for corporate planning"
                    employees="1150"
                    valuation="$1.4B"
                    url="https://anaplan.com" />


                <Unicorn
                    name="AppDirect"
                    location="San Francisco, CA"
                    year="2009"
                    nationality="🇨🇦 Canada"
                    founder="Nicolas Desmarais, Daniel Saks"
                    description="Commerce platform for selling cloud services"
                    employees="700"
                    valuation="$1.1B"
                    url="https://appdirect" />


                <Unicorn
                    name="Apptus"
                    location="San Mateo, CA"
                    year="2012"
                    nationality="🇬🇧 United Kingdom"
                    founder="Kirk Krappe"
                    description="Software built on the salesforce platform"
                    employees="1200"
                    valuation="$1.4B"
                    url="https://apptus.com" />


                <Unicorn
                    name="Avant"
                    location="Chicago, IL"
                    year="2012"
                    nationality="🇨🇳 China"
                    founder="Paul Zhang, Al Goldstein, John Sun"
                    description="Online consumer credit and technology for financial companies"
                    employees="500"
                    valuation="$2B"
                    url="https://avant.com" />

                <Unicorn
                    name="Cloudfare"
                    location="San Francisco, CA"
                    year="2009"
                    nationality="🇨🇦 Canada"
                    founder="Michelle Zatlyn"
                    description="Global network for websites"
                    employees="715"
                    valuation="$1B"
                    url="https://cloudflare.com" />

                <Unicorn
                    name="Compass"
                    location="New York, NY"
                    year="2012"
                    nationality="🇮🇹 Italy"
                    founder="Ugo Di Girolamo, Ori Allon"
                    description="Technology driven real estate platform"
                    employees="1000"
                    valuation="$2.2B"
                    url="https://compass.com" />

                <Unicorn
                    name="Credit Karma"
                    location="San Francisco, CA"
                    year="2007"
                    nationality="🇨🇳 China"
                    founder="Kenneth Lin"
                    description="Consumer finance and credit"
                    employees="800"
                    valuation="$3.5B"
                    url="https://creditkarma.com" />

                <Unicorn
                    name="CrowdStrike"
                    location="Irvine, CA"
                    year="2011"
                    nationality="🇷🇺 Russia"
                    founder="Dmitri Alperovitch"
                    description="Cybersecurity"
                    employees="1200"
                    valuation="$3B"
                    url="https://crowdstrike.com" />

                <Unicorn
                    name="Cybereason"
                    location="Boston, MA"
                    year="2012"
                    nationality="🇮🇱 Israel"
                    founder="Yossi Naar"
                    description="Cybersecurity"
                    employees="375"
                    valuation="$1B"
                    url="https://cybereason.com" />

                <Unicorn
                    name="Discord"
                    location="San Francisco, CA"
                    year="2012"
                    nationality="🇺🇦 Ukraine"
                    founder="Stanislav Vishnevskiy"
                    description="Gaming communication"
                    employees="100"
                    valuation="$1.6B"
                    url="https://discord.com" />

                <Unicorn
                    name="Eventbrite"
                    location="San Francisco, CA"
                    year="2009"
                    nationality="🇫🇷 France"
                    founder="Renaud Visage"
                    description="Self service ticketing platform for events"
                    employees="900"
                    valuation="$1B"
                    url="https://eventbrite.com" />

                <Unicorn
                    name="Evernote"
                    location="Redwood City, CA"
                    year="2004"
                    nationality="🇦🇿 Azerbaijan"
                    founder="Stepan Pachikov"
                    description="Software for organizing information and note taking"
                    employees="336"
                    valuation="$1B"
                    url="https://evernote.com" />

                <Unicorn
                    name="FanDuel"
                    location="New York, NY"
                    year="2011"
                    nationality="🇬🇧 United Kingdom"
                    founder="Lesley Eccles, Nigel Eccles, Tom Griffiths"
                    description="Fantasy sports"
                    employees="550"
                    valuation="$1.3B"
                    url="https://fanduel.com" />

                <Unicorn
                    name="Ginkgo Bioworks"
                    location="Boston, MA"
                    year="2008"
                    nationality="🇮🇪 Ireland"
                    founder="Barry Canton"
                    description="Designs custom microbes"
                    employees="200"
                    valuation="$1.0B"
                    url="https://ginkgobioworks.com" />

                <Unicorn
                    name="Gusto"
                    location="San Francisco, Ca"
                    year="2011"
                    nationality="🇮🇱 Israel"
                    founder="Tomer London"
                    description="Automates human resources functions, build company culture"
                    employees="600"
                    valuation="$1.1B"
                    url="https://gusto.com" />

                <Unicorn
                    name="Houzz"
                    location="Palo Alto, CA"
                    year="2009"
                    nationality="🇮🇱 Israel"
                    founder="Alon Cohen, Adi Tatarko"
                    description="Platform for home modeling and redesign"
                    employees="1800"
                    valuation="$4B"
                    url="https://houzz.com" />

                <Unicorn
                    name="Infinidat"
                    location="Needham, MA"
                    year="2010"
                    nationality="🇮🇱 Israel"
                    founder="Moshe Yanai"
                    description="Data protection and storage"
                    employees="519"
                    valuation="$1.6B"
                    url="https://infinidat.com" />

                <Unicorn
                    name="Instacart"
                    location="San Francisco, CA"
                    year="2012"
                    nationality="🇨🇦 Canada"
                    founder="Apoorva Mehta"
                    description="Internet based grocery delivery"
                    employees="560"
                    valuation="$4.2B"
                    url="https://instacart.com" />

                <Unicorn
                    name="JetSmarter"
                    location="Fort Lauderdale, FL"
                    year="2012"
                    nationality="🇷🇺 Russia"
                    founder="Sergey Petrossov"
                    description="Technology to connect people via private air travel"
                    employees="260"
                    valuation="$1.6B"
                    url="https://flyxo.com" />

                <Unicorn
                    name="Medallia"
                    location="Palo Alto, CA"
                    year="2000"
                    nationality="🇳🇴 Norway"
                    founder="Borge Hald"
                    description="Software to help companies better serve people"
                    employees="1300"
                    valuation="$1.3B"
                    url="https://medallia.com" />

                <Unicorn
                    name="Modernatx"
                    location="Cambridge, MA"
                    year="2009"
                    nationality="🇨🇦 Canada"
                    founder="Derrick Rossi, Noubar Afeyan"
                    description="Developer of drugs with messenger RNA"
                    employees="645"
                    valuation="$7B"
                    url="https://modernatx.com" />

                <Unicorn
                    name="Mu Sigma"
                    location="Northbrook, IL"
                    year="2004"
                    nationality="🇮🇳 India"
                    founder="Dhiraj Rajaram"
                    description="Big data and analytics solutions for companies"
                    employees="3500"
                    valuation="$1.5B"
                    url="https://mu-sigma.com" />

                <Unicorn
                    name="OfferUp"
                    location="Bellevue, WA"
                    year="2011"
                    nationality="🇳🇱 Netherlands"
                    founder="Arean van Veelen"
                    description="Online classifieds"
                    employees="240"
                    valuation="$1.3B"
                    url="https://offerup.com" />

                <Unicorn
                    name="HiOscar"
                    location="New York, NY"
                    year="2012"
                    nationality="🇩🇪 Germany"
                    founder="Mario Schlosser"
                    description="Health Insurance"
                    employees="850"
                    valuation="$2.7B"
                    url="https://hioscar.com" />

                <Unicorn
                    name="Palantir"
                    location="Palo Alto, CA"
                    year="2004"
                    nationality="🇩🇪 Germany"
                    founder="Peter Thiel"
                    description="Big data analytics"
                    employees="2000"
                    valuation="20B"
                    url="https://palantir.com" />

                <Unicorn
                    name="Peloton"
                    location="New York, NY"
                    year="2012"
                    nationality="🇨🇳 China"
                    founder="Yony Feng"
                    description="Technology for group fitness"
                    employees="1000"
                    valuation="$1.3B"
                    url="https://onepeloton.com" />

                <Unicorn
                    name="Proteus"
                    location="Redwood City, CA"
                    year="2001"
                    nationality="🇬🇧 United Kingdom"
                    founder="Andrew Thompson"
                    description="Products and services for managing patient health"
                    employees="300"
                    valuation="$1.1B"
                    url="https://proteus.com" />

            </div>

            <div className="quote-column">

                <Unicorn
                    name="Quanergy"
                    location="Sunnyvale, CA"
                    year="2012"
                    nationality="🇱🇧 Lebanon"
                    founder="Louay Eldada, Tianyue Yu"
                    description="LiDAR sensors and software"
                    employees="250"
                    valuation="$1.6B"
                    url="https://quanergy.com" />

                <Unicorn
                    name="Robinhood"
                    location="Palo Alto, CA"
                    year="2012"
                    nationality="🇧🇬 Bulgaria"
                    founder="Vlad Tenev"
                    description="Stock brokerage"
                    employees="250"
                    valuation="$5.6B"
                    url="https://robinhood.com" />

                <Unicorn
                    name="Rubrik"
                    location="Palo Alto, CA"
                    year="2004"
                    nationality="🇮🇳 India"
                    founder="Arvind Jain, Bipul Sinha, Arvind Nithrakashyap, Soham Mazumdar"
                    description="Cloud data storage and management"
                    employees="1200"
                    valuation="$1.3B"
                    url="https://rubrik.com" />

                <Unicorn
                    name="Slack"
                    location="San Francisco, CA"
                    year="2009"
                    nationality="🇨🇦 Canada"
                    founder="Stewart Butterfield, Cal Henderson, Sergei Mourachov"
                    description="Team Messaging"
                    employees="1000"
                    valuation="$7.1B"
                    url="https://slack.com" />

                <Unicorn
                    name="SpaceX"
                    location="Hawthorne, CA"
                    year="2002"
                    nationality="🇿🇦 South Africa"
                    founder="Elon Musk"
                    description="Launches rockets into space"
                    employees="7000"
                    valuation="$21B"
                    url="https://spacex.com" />

                <Unicorn
                    name="Sprinklr"
                    location="New York, NY"
                    year="2011"
                    nationality="🇮🇳 India"
                    founder="Ragy Thomas"
                    description="Social media software platform"
                    employees="1400"
                    valuation="$1.8B"
                    url="https://sprinklr.com" />

                <Unicorn
                    name="Stripe"
                    location="San Francisco, CA"
                    year="2009"
                    nationality="🇮🇪 Ireland"
                    founder="John Collison, Patrick Collison"
                    description="Digital payments company"
                    employees="1100"
                    valuation="$20.0B"
                    url="https://stripe.com" />

                <Unicorn
                    name="Symphony"
                    location="Palo Alto, CA"
                    year="2014"
                    nationality="🇫🇷 France"
                    founder="David Gurle"
                    description="Integrated Messaging Platform"
                    employees="260"
                    valuation="$1.0B"
                    url="https://symphony.com" />

                <Unicorn
                    name="Tango"
                    location="Mountain View, CA"
                    year="2009"
                    nationality="🇫🇷 France"
                    founder="Eric Setton, Uri Raz"
                    description="Mobile messaging service"
                    employees="500"
                    valuation="$1.0B"
                    url="https://" />

                <Unicorn
                    name="Tanium"
                    location="Emeryville, CA"
                    year="2007"
                    nationality="🇮🇶 Iraq"
                    founder="David Hindawi"
                    description="IT Security and Systems Management"
                    employees="750"
                    valuation="$3.5B"
                    url="https://tanium.com" />

                <Unicorn
                    name="Uber"
                    location="San Francisco, CA"
                    year="2009"
                    nationality="🇨🇦 Canada"
                    founder="Garrett Camp"
                    description="Ridesharing"
                    employees="9300"
                    valuation="$72B"
                    url="https://uber.com" />

                <Unicorn
                    name="Udacity"
                    location="Palo Alto, CA"
                    year="2011"
                    nationality="🇩🇪 Germany"
                    founder="Sebastian Thrun"
                    description="Online education company"
                    employees="400"
                    valuation="$1.0B"
                    url="https://udacity.com" />

                <Unicorn
                    name="View"
                    location="Milpitas, CA"
                    year="2007"
                    nationality="🇻🇳 Vietnam"
                    founder="Paul Nguyen"
                    description="Dynamic glass"
                    employees="750"
                    valuation="$1.1B"
                    url="https://view.com" />

                <Unicorn
                    name="Warby Parker"
                    location="New York, NY"
                    year="2009"
                    nationality="🇸🇪 Sweden"
                    founder="David Gilboa"
                    description="Online prescription glasses"
                    employees="1400"
                    valuation="$1.2B"
                    url="https://warbyparker.com" />

                <Unicorn
                    name="Wish"
                    location="San Francisco, CA"
                    year="2012"
                    nationality="🇨🇦 Canada"
                    founder="Peter Szulczewski, Danny Zhang"
                    description="Mobile shopping"
                    employees="640"
                    valuation="$4.3B"
                    url="https://wish.com" />

                <Unicorn
                    name="Zenefits"
                    location="San Francisco, CA"
                    year="2013"
                    nationality="🇮🇳 India"
                    founder="Laks Srini"
                    description="Software for HR"
                    employees="500"
                    valuation="$2.0B"
                    url="https://zenefits.com" />

                <Unicorn
                    name="ZocDoc"
                    location="New York, NY"
                    year="2007"
                    nationality="🇩🇪 Germany"
                    founder="Oliver Kharraz"
                    description="Online scheduling service for medical care"
                    employees="500"
                    valuation="$1.8B"
                    url="https://zocdoc.com" />

                <Unicorn
                    name="Zoom Video"
                    location="San Jose, CA"
                    year="2014"
                    nationality="🇨🇳 China"
                    founder="Eric Yuan"
                    description="Cloud platform for audio and video conferencing"
                    employees="1300"
                    valuation="$1B"
                    url="https://zoom.us" />

                <Unicorn
                    name="Zoox"
                    location="Palo Alto, CA"
                    year="2014"
                    nationality="🇦🇺 Australia"
                    founder="Tim Kentley Khay"
                    description="Autonomous vehicles"
                    employees="500"
                    valuation="$3.2B"
                    url="https://" />

                <Unicorn
                    name="AppDynamics"
                    location="San Francisco, CA"
                    year="2008"
                    nationality="🇮🇳 India"
                    founder="Jyoti Bansal"
                    description="Application performance management"
                    employees="1548"
                    valuation="$3.7B, Acquired by Cisco"
                    url="https://zoox.com" />

                <Unicorn
                    name="AppNexus"
                    location="New York, NY"
                    year="2007"
                    nationality="🇳🇱 Netherlands"
                    founder="Mike Nolet"
                    description="Cloud-based software for online advertising"
                    employees="1000"
                    valuation="$1B, Acquired by AT&T"
                    url="https://appnexus.com" />

                <Unicorn
                    name="Bloom Energy"
                    location="Sunnyvale, CA"
                    year="2001"
                    nationality="🇮🇳 India"
                    founder="KR Sridhar"
                    description="Power generation"
                    employees="1200"
                    valuation="$3B"
                    url="https://bloomenergy.com" />

                <Unicorn
                    name="Cloudera"
                    location="Palo Alto, CA"
                    year="2008"
                    nationality="🇪🇬 Egypt"
                    founder="Amr Awadallah"
                    description="Platform for data management and software"
                    employees="1600"
                    valuation="$2.2B"
                    url="https://cloudera.com" />

                <Unicorn
                    name="GreenSky"
                    location="Atlanta, GA"
                    year="2006"
                    nationality="🇮🇱 Israel"
                    founder="David Zalik"
                    description="Provider of technology to banks and others for consumer loans"
                    employees="1000"
                    valuation="$2.9B"
                    url="https://greensky.com" />

                <Unicorn
                    name="Jasper"
                    location="Santa Clara, CA"
                    year="2004"
                    nationality="🇮🇳 India"
                    founder="Jahangir Mohammed"
                    description="Cloud based platform for the internet"
                    employees="425"
                    valuation="$1.4B, Acquired by Cisco"
                    url="https://jaspersolutions.com" />

                <Unicorn
                    name="MuleSoft"
                    location="San Francisco, CA"
                    year="2003"
                    nationality="🇬🇧 United Kingdom"
                    founder="Ross Mason"
                    description="Integration platform for connection applications"
                    employees="1100"
                    valuation="$6.5B, Acquired by Cisco"
                    url="https://mulesoft.com" />

                <Unicorn
                    name="Nutanix"
                    location="San Jose, CA"
                    year="2004"
                    nationality="🇮🇳 India"
                    founder="Mohit Aron, Dheeraj Pandey, Ajeet Singh"
                    description="Solutions for enterprise data centers"
                    employees="3200"
                    valuation="$2B"
                    url="https://nutanix.com" />

                <Unicorn
                    name="Razer"
                    location="Irvine, CA"
                    year="2005"
                    nationality="🇸🇬 Singapore"
                    founder="Min-Liang Tan"
                    description="Products directed at gamers"
                    employees="1391"
                    valuation="$1B"
                    url="https://razer.com" />

                <Unicorn
                    name="Zscaler"
                    location="San Jose, CA"
                    year="2007"
                    nationality="🇮🇳 India"
                    founder="Jay Caudhry"
                    description="Computer and network security"
                    employees="950"
                    valuation="$1B"
                    url="https://zscaler.com" />
            </div>
        </div>

    )

}

export default Unicorns
